import axiosBase from './AxiosBase';

export default class HelpService {

    async getHelpList(data) {
        return axiosBase.post('Help/ListCategoryContent/'+ data).then(res => res.data);
    }

    async addHelpContent(data) {
        return axiosBase.post('Help/Add', data).then(res => res.data);
    }
    async updateHelpContent(data) {
        return axiosBase.post('Help/Update', data).then(res => res.data);
    }

    async addHelpCategory(data) {
        return axiosBase.post('Help/AddCategory', data).then(res => res.data);
    }
    async updateHelpCategory(data) {
        return axiosBase.post('Help/UpdateCategory', data).then(res => res.data);
    }

    async getHelpCategoryList(data) {
        return axiosBase.post('Help/ListCategory', data).then(res => res.data);
    }
    async getHelpContentList(data) {
        return axiosBase.post('Help/ListContent', data).then(res => res.data);
    }
}
