import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import UserService from '../service/UserService';

import { useHistory } from 'react-router-dom';

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const dt = useRef(null);
    const history = useHistory();


    const [lazyParams, setLazyParams] = useState({
        first: 0,
        row: 20,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            'nameSurname': { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            'company': { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' },
        }
    });

    const userService = new UserService();

    let loadLazyTimeout = null;

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        if (globalFilter.length > 2 || globalFilter.length == 0)
            loadLazyData();
    }, [globalFilter])

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        userService.getUserList({
            "getAllData": false,
            "pageNumber": lazyParams.page,
            "pageSize": lazyParams.row,
            "SearchValue": globalFilter,
            "usertype": 0
        }).then(data => {
            setTotalRecords(data.data.totalCount);
            setCustomers(data.data.resultSet);
            setLoading(false);
        });
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const birthdayBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="image-text">{rowData.birthday && new Date(rowData.birthday).toLocaleDateString('tr', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })}</span>
            </React.Fragment>
        );
    }

    const mobilePhoneBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="image-text">{rowData.mobilePhone}</span>
            </React.Fragment>
        );
    }

    const redirectBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-external-link" className="p-button-outlined p-button-info " style={{ color: '#7C5F00' }} onClick={() => history.push('/UserSummary/' + rowData.idUser + '/' + rowData.rolAdi)} label='Hesap Özeti' />
            </div>
        );
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Kullanıcıları Yönet</h5>
            <div className="flex flex-row ">
                <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
                <Button label="Excel Aktar" icon="pi pi-upload" className="p-button-raised p-button-secondary" onClick={exportCSV} />
            </div>
        </div>
    );

    return (

        <div className="card">
            <DataTable loading={loading} ref={dt} value={customers} paginator
                header={header} totalRecords={totalRecords} lazy onPage={onPage} first={lazyParams.first}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} toplam kayıttan, {first} ile {last} arasındaki kayıtlar gösteriliyor. "
                rows={20}
                selectionMode="single" selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}
                className="datatable-responsive" size="large"
                showGridlines responsiveLayout="scroll" selectionPageOnly
                showSelectAll={false}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="nameSurname" header="İsim Soyisim"></Column>
                <Column field="rolAdi" header="Üyelik Türü"></Column>
                <Column field="mobilePhone" body={mobilePhoneBodyTemplate} header="Telefon Numarası"></Column>
                <Column field="cinsiyet" header="Cinsiyet"></Column>
                <Column field="birthday" body={birthdayBodyTemplate} header="Doğum Günü"></Column>
                <Column field="mail" header="E-Posta"></Column>
                <Column body={redirectBodyTemplate} header=""></Column>
            </DataTable>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);