import React, { useState, useRef, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import DashboardService from '../service/DashboardService';
import { Tooltip } from 'primereact/tooltip';
import { useHistory } from 'react-router-dom';


const visitorChart = {
    labels: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
    datasets: [
        {
            label: 'Yeni Üye',
            data: [0],
            borderDash: [5, 5],
            type: 'line',
            fill: true,
            yAxisID: 'y1',
            borderColor: 'red',
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderWidth: 2
        },
        {
            label: 'Kullanılan Puan',
            data: [0],
            backgroundColor: '#FFA726',
            fill: true,
            yAxisID: 'y',
            barPercentage: 0.9
        },
        {
            label: 'Yüklenen puan',
            data: [0],
            backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
            fill: true,
            yAxisID: 'y',
            barPercentage: 0.9
        }
    ]
};

const visitorChartOptions = {

    plugins: {
        legend: {
            position: 'top',
            align: 'end'
        }
    },
    responsive: true,
    hover: {
        mode: 'index'
    },
    scales: {
        y: {

            grid: {
                display: true
            }
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
                display: false
            }
        }
        ,
        x: {
            grid: {
                display: true
            }
        }
    }
};

const topProductPointChart = {
    labels: [''],
    datasets: [
        {
            data: [0],
            backgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            hoverBackgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            borderColor: 'transparent',
            fill: true
        }
    ]
};

const topProductPointChartOptions = {
    responsive: true
};

const revenueChart = {
    labels: [''],
    datasets: [
        {
            label: 'Üye Sayısı',
            data: [0],
            borderColor: '#FFA726',
            backgroundColor: 'rgba(255,167,38,0.2)',
            fill: true,
            tension: .4
        }],
};

const revenueChartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            align: 'end'
        }
    },
    hover: {
        mode: 'index'
    },
    scales: {
        x: {
            ticks: {
                /*callback: function (val, index) {
                    var showDate = new Date(this.getLabelForValue(val)).toLocaleDateString('tr', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                    })
                    return showDate;
                },*/
                maxRotation: 90,
                minRotation: 90
            }
        },
        y: {
            min: -1,
            ticks: {
                stepSize: 1
            }
        }
    }
};

const barcodeStatusChart = {
    labels: [''],
    datasets: [
        {
            data: [0],
            backgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            hoverBackgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            borderColor: 'transparent',
            fill: true
        }
    ]
};

const DashboardSeller = () => {

    const history = useHistory();
    const dashboardService = new DashboardService();
    const appID = 43;
    const today = new Date();

    const [pointMonthlyPercentChange, setPointMonthlyPercentChange] = useState(null);
    const [yearlyPointSummary, setYearlyPointSummary] = useState(null);
    const [topProductPoint, setTopProductPoint] = useState([]);
    const [topPointEarnedUser, setTopPointEarnedUser] = useState([]);
    const [topPointAmountUser, setTopPointAmountUser] = useState([]);
    const [topPointUsedUser, setTopPointUsedUser] = useState([]);

    const [selectedTopProductPeriod, setSelectedTopProductPeriod] = useState(30);
    const [selectedTopEarnedUserPeriod, setSelectedTopEarnedUserPeriod] = useState(30);

    const [lastReadBarcode, setLastReadBarcode] = useState([]);
    const [barcodeStatus, setBarcodeStatus] = useState([]);

    const [selectedVisitorYear, setSelectedVisitorYear] = useState(null);

    const visitor = useRef(null);
    const ProductPointPie = useRef(null);
    const BarcodeStatusPie = useRef(null);
    const revenue = useRef(null);
    const dt = useRef(null)

    let monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

    useEffect(() => {

        dashboardService.getPointMonthlyPercentChange(appID).then(res => {
            setPointMonthlyPercentChange(res.data)
        })

        ChangeMonthlyPoint(today.getFullYear());
        setSelectedVisitorYear(today.getFullYear().toString());

        dashboardService.getBarcodeStatus(appID).then(res => {
            setBarcodeStatus(res.data)
            barcodeStatusChart.labels = res.data.map(item => item.value)
            barcodeStatusChart.datasets[0].data = res.data.map(item => item.totalBarcode)
            BarcodeStatusPie?.current?.refresh()
        })

        ChangeTopPointEarnedUser(0);
        ChangeTopProduct(0);

        dashboardService.getTopPointAmountUsedUser(appID, 10, 1).then(res => setTopPointAmountUser(res.data));
        dashboardService.getTopPointAmountUsedUser(appID, 10, 0).then(res => setTopPointUsedUser(res.data));

        dashboardService.getDailyUserCount(appID, 20).then(res => {
            //setDailyUserCount(res.data)
            revenueChart.labels = res.data.map(item => new Date(item.date).toLocaleDateString('tr', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            })).reverse()
            revenueChart.datasets[0].data = res.data.map(item => item.totalUser).reverse()
            revenue?.current?.refresh()
        })

        dashboardService.getLastReadBarcode(appID, 30).then(res => {
            setLastReadBarcode(res.data)
        })

        return () => {
            setPointMonthlyPercentChange([])
            setLastReadBarcode([])
            setBarcodeStatus([])
            barcodeStatusChart.labels = []
            barcodeStatusChart.datasets[0].data = []
            revenueChart.datasets[0].data = []
            setTopPointAmountUser([])
            setTopPointUsedUser([])
        }
    }, [])

    // Yeni Üye / Puan Dağılımı
    const ChangeMonthlyPoint = async (year) => {
        dashboardService.getMonthlyPointSummary(appID, year).then(res => {
            const monthYear = res.data.filter(e => e.type.includes('UsedPoints')).map(item => item.year + '-' + item.month);
            visitorChart.labels = monthYear.map(item => item.split('-')[0] + ' ' + monthNames[item.split('-')[1] - 1]);
            visitorChart.datasets[1].data = res.data.filter(e => e.type.includes('UsedPoints')).map(item => item.total)
            visitorChart.datasets[2].data = res.data.filter(e => e.type.includes('EarnedPoints')).map(item => item.total)
            visitorChart.datasets[0].data = res.data.filter(e => e.type.includes('Users')).map(item => item.total)
            visitor?.current?.refresh();
        })
        dashboardService.getYearlySummary(appID, year).then(res => {
            setYearlyPointSummary(res.data)
        })
    }

    // En İyi Ustalar - En çok puan yükleyen Ustalar
    const ChangeTopPointEarnedUser = async (period) => {
        dashboardService.getTopPointEarnedUser(appID, 10, period).then(res => {
            setTopPointEarnedUser(res.data)
        })
    }

    // Ürün / Yüklenen Puan Dağılımı
    const ChangeTopProduct = async (period) => {
        dashboardService.getTopProductPoint(appID, 7, period).then(res => {
            setTopProductPoint(res.data)
            topProductPointChart.labels = res.data.map(item => item.productTypeTxt)
            topProductPointChart.datasets[0].data = res.data.map(item => item.totalProduct)
            ProductPointPie?.current?.refresh();
        })
    }

    const changeVisitorChart = (event) => {
        setSelectedVisitorYear(event.value)
        ChangeMonthlyPoint(event.value);
    };

    const changeTopProductChart = (event) => {
        setSelectedTopProductPeriod(event.value)
        ChangeTopProduct(event.value);
    };

    const changeTopPointUserChart = (event) => {
        setSelectedTopEarnedUserPeriod(event.value)
        ChangeTopPointEarnedUser(event.value);
    };

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 md:col-4">
                    <div className={"card widget-overview-box " + (pointMonthlyPercentChange?.userChangePercent < 0 ? 'widget-overview-box-1' : 'widget-overview-box-2')}>
                        <span className="overview-title">
                            TOPLAM TEZGAHTAR
                        </span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center" id='idUserChangePercent'>
                                    <i className={"pi " + (pointMonthlyPercentChange?.userChangePercent < 0 ? 'pi-arrow-down' : 'pi-arrow-up')}></i>
                                    <span>{pointMonthlyPercentChange?.userChangePercent}%</span>
                                </div>

                                <Tooltip target="#idUserChangePercent" mouseTrack mouseTrackTop={15} position='bottom' style={{ width: 200 }} >
                                    <h5>Bu Ay:</h5>
                                    <h6>{pointMonthlyPercentChange?.thisMonthUser?.toLocaleString('tr', { style: 'decimal' })} Yeni Tezgahtar</h6>
                                    <h5>Geçen Ay:</h5>
                                    <h6>{pointMonthlyPercentChange?.lastMonthUser?.toLocaleString('tr', { style: 'decimal' })} Yeni Tezgahtar</h6>
                                    <h5>Değişim:</h5>
                                    <h6>{pointMonthlyPercentChange?.userChangePercent}%</h6>
                                </Tooltip>
                                <div className="text-4xl md:text-2xl line-height-3 md:pt-2 xl:text-4xl xl:pt-0">
                                    {pointMonthlyPercentChange?.totalUser?.toLocaleString('tr', { style: 'decimal' })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-4">
                    <div className={"card widget-overview-box " + (pointMonthlyPercentChange?.earnedChangePercent < 0 ? 'widget-overview-box-1' : 'widget-overview-box-2')}>
                        <span className="overview-title">
                            YÜKLENEN PUAN
                        </span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center" id='idEarnedChangePercent'>
                                    <i className={"pi " + (pointMonthlyPercentChange?.earnedChangePercent < 0 ? 'pi-arrow-down' : 'pi-arrow-up')}></i>
                                    <span>{pointMonthlyPercentChange?.earnedChangePercent}%</span>
                                </div>
                                <Tooltip target="#idEarnedChangePercent" mouseTrack mouseTrackTop={15} position='bottom' style={{ width: 200 }}>
                                    <h5>Bu Ay Yüklenen:</h5>
                                    <h6>{pointMonthlyPercentChange?.thisMonthEarned?.toLocaleString('tr', { style: 'decimal' })} Para Puan</h6>
                                    <h5>Geçen Ay Yüklenen:</h5>
                                    <h6>{pointMonthlyPercentChange?.lastMonthEarned?.toLocaleString('tr', { style: 'decimal' })} Para Puan</h6>
                                    <h5>Değişim:</h5>
                                    <h6>{pointMonthlyPercentChange?.earnedChangePercent}%</h6>
                                </Tooltip>
                                <div className="text-4xl md:text-2xl line-height-3 md:pt-2 xl:text-4xl xl:pt-0">
                                    {pointMonthlyPercentChange?.earnedPoint?.toLocaleString('tr', { style: 'decimal' })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-12 md:col-4">
                    <div className={"card widget-overview-box " + (pointMonthlyPercentChange?.usedChangePercent < 0 ? 'widget-overview-box-1' : 'widget-overview-box-2')}>
                        <span className="overview-title">
                            KULLANILAN PUAN
                        </span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center" id='idUsedChangePercent'>
                                    <i className={"pi " + (pointMonthlyPercentChange?.usedChangePercent < 0 ? 'pi-arrow-down' : 'pi-arrow-up')}></i>
                                    <span>{pointMonthlyPercentChange?.usedChangePercent}%</span>
                                </div>
                                <Tooltip target="#idUsedChangePercent" mouseTrack mouseTrackTop={15} position='bottom' style={{ width: 200 }} >
                                    <h5>Bu Ay Kullanılan:</h5>
                                    <h6>{pointMonthlyPercentChange?.thisMonthUsed?.toLocaleString('tr', { style: 'decimal' })} Para Puan</h6>
                                    <h5>Geçen Ay Kullanılan:</h5>
                                    <h6>{pointMonthlyPercentChange?.lastMonthUsed?.toLocaleString('tr', { style: 'decimal' })} Para Puan</h6>
                                    <h5>Değişim:</h5>
                                    <h6>{pointMonthlyPercentChange?.usedChangePercent}%</h6>
                                </Tooltip>
                                <div className="text-4xl md:text-2xl line-height-3 md:pt-2 xl:text-4xl xl:pt-0">
                                    {pointMonthlyPercentChange?.usedPoint?.toLocaleString('tr', { style: 'decimal' })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-8">
                    <div className="card widget-visitor-graph">
                        <div className="card-header">
                            <span>Yeni Üye / Puan Dağılımı</span>
                            <Dropdown
                                options={[{ name: '2024', value: '2024' }, { name: '2023', value: '2023' }]}
                                value={selectedVisitorYear}
                                optionLabel="name"
                                onChange={changeVisitorChart} />
                        </div>

                        <div className="graph-content grid">
                            <div className="col-12 md:col-4">
                                <h2>{yearlyPointSummary?.totalUser}</h2>
                                <h6>Yeni Üye</h6>
                                <p>Seçilen yıla göre yeni üyelik başlatan tezgahtarlar toplamı</p>
                            </div>
                            <div className="col-12 md:col-4">
                                <h2>{yearlyPointSummary?.earnedPoint.toLocaleString('tr', { style: 'decimal' })}</h2>
                                <h6>Yüklenen Puan</h6>
                                <p>Seçilen yıla göre tezgahtarların yükledikleri puanlar toplamı</p>
                            </div>
                            <div className="col-12 md:col-4">
                                <h2>{yearlyPointSummary?.usedPoint.toLocaleString('tr', { style: 'decimal' })}</h2>
                                <h6>Harcanan Puan</h6>
                                <p>Seçilen yıla göre tezgahtarın kullandıkları puanlar toplamı</p>
                            </div>
                        </div>

                        <div className="graph">
                            <h6>Seçilen Yıla Göre Yeni Üye / Puan Hareketleri</h6>
                            <Chart ref={visitor} type="bar" data={visitorChart} options={visitorChartOptions} id="visitor-chart"></Chart>
                        </div>
                    </div>
                </div>

                <div className="col-12 sm:col-6 md:col-4 lg:col-6 xl:col-4">
                    <div className="card widget-country-graph">
                        <div className="card-header">
                            <span>Barkod Durum Dağılımı</span>
                        </div>
                        <p>Üretilmiş puanların son durumlarının dağılımı</p>
                        <div className="country-graph flex justify-content-center">
                            <Chart type="pie" ref={BarcodeStatusPie} id="country-chart" data={barcodeStatusChart} options={topProductPointChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                        </div>
                        <div className="country-content">
                            <ul>
                                {barcodeStatus.map((item, index) =>
                                    <li className="flex justify-content-between align-items-center" key={index}>
                                        <div className="flex justify-content-between align-items-center">
                                            <div className="color" style={{ backgroundColor: '' + barcodeStatusChart.datasets[0].backgroundColor[index] + '', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                            <div className="flex flex-column justify-content-start align-items-start">
                                                <span>{item.value}</span>
                                                <span>{Number(item.totalBarcode).toLocaleString('tr', { style: 'decimal' })} Adet</span>
                                            </div>
                                        </div>
                                    </li>

                                )}

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                    <div className="card widget-performance">

                        <div className="card-header">
                            <span>En İyi Tezgahtarlar</span>
                            <Dropdown
                                options={[{ name: 'Son 90 Gün', value: 90 }, { name: 'Son 60 Gün', value: 60 }, { name: 'Son 30 Gün', value: 30 }, { name: 'Tüm Zamanlar', value: 0 }]}
                                value={selectedTopEarnedUserPeriod}
                                optionLabel="name"
                                onChange={changeTopPointUserChart} />
                        </div>
                        <p className="subtitle">En çok puan yükleyen Tezgahtarlar</p>

                        <div className="content">
                            <ul>
                                {topPointEarnedUser.map((item, index) =>
                                    <li className="person-item" key={index}>
                                        <Avatar image="assets/layout/images/dashboard/profile.png" className="mr-2 p-overlay-badge" shape="circle"><Badge size='normal' value={item.countPoint.toLocaleString('tr', { style: 'decimal' })} /></Avatar>
                                        <div className="person-info cursor-pointer" onClick={() => history.push('/UserSummary/' + item.idUser)}>
                                            <div className="amount">{item.totalPoint.toLocaleString('tr', { style: 'decimal' })} Para Puan</div>
                                            <div className="name">{item.nameSurname}</div>
                                            <div className="name"><strong>Bakiye:</strong> {item.amount.toLocaleString('tr', { style: 'decimal' })} Para Puan</div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 sm:col-12 md:col-6 lg:col-12 xl:col-6">
                    <div className="card widget-country-graph">
                        <div className="card-header">
                            <span>Ürün / Yüklenen Puan Dağılımı</span>
                            <Dropdown
                                options={[{ name: 'Son 90 Gün', value: 90 }, { name: 'Son 60 Gün', value: 60 }, { name: 'Son 30 Gün', value: 30 }, { name: 'Tüm Zamanlar', value: 0 }]}
                                value={selectedTopProductPeriod}
                                optionLabel="name"
                                onChange={changeTopProductChart} />
                        </div>
                        <p>En çok puan yüklenen ürünler</p>
                        <div className="country-graph flex justify-content-center">
                            <Chart type="doughnut" ref={ProductPointPie} id="country-chart" data={topProductPointChart} options={topProductPointChartOptions} style={{}}></Chart>
                        </div>
                        <div className="country-content">
                            <ul>
                                {topProductPoint.map((item, index) =>
                                    <li className="flex justify-content-between align-items-center" key={index}>
                                        <div className="flex justify-content-between align-items-center">
                                            <div className="color" style={{ backgroundColor: '' + topProductPointChart.datasets[0].backgroundColor[index] + '', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                            <div className="flex flex-column justify-content-start align-items-start">
                                                <span>{item.productTypeTxt}</span>
                                                <span>{item.earnedPoint.toLocaleString('tr', { style: 'decimal' })} Puan</span>
                                            </div>
                                        </div>
                                        <span>{item.totalProduct.toLocaleString('tr', { style: 'decimal' })} Adet</span>
                                    </li>

                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className="card widget-table">
                        <div className="card-header">
                            <span>En Çok Aktif Puanı Olan Tezgahtarlar</span>
                        </div>
                        <DataTable className="p-datatable-customers" value={topPointAmountUser} dataKey="idBarcodes" rowHover rows={15} onRowClick={(d) => history.push('/UserSummary/' + d.data.idUser)}>
                            <Column style={{ cursor: 'pointer' }} field="nameSurname" header="İsim Soyisim" body={(data) => data.nameSurname}></Column>
                            <Column field="mobilePhone" header="Telefon Numarası" body={(data) => data.mobilePhone}></Column>
                            <Column field="value" header="Puan" body={(data) => data.amount.toLocaleString('tr', { style: 'decimal' }) + ' PP'}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className="card widget-table">
                        <div className="card-header">
                            <span>En Çok Puan Kullanan Tezgahtarlar</span>
                        </div>
                        <DataTable className="p-datatable-customers" value={topPointUsedUser} dataKey="idBarcodes" rowHover rows={15} onRowClick={(d) => history.push('/UserSummary/' + d.data.idUser)}>
                            <Column style={{ cursor: 'pointer' }} field="nameSurname" header="İsim Soyisim" body={(data) => data.nameSurname}></Column>
                            <Column field="mobilePhone" header="Telefon Numarası" body={(data) => data.mobilePhone}></Column>
                            <Column field="value" header="Puan" body={(data) => data.usedPoint.toLocaleString('tr', { style: 'decimal' }) + ' PP'}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 md:col-12">
                    <div className="card widget-revenue-graph">
                        <div className="card-header">
                            <span>Günlük Yeni Üye Dağılımı</span>
                        </div>

                        <div className="graph">
                            <Chart ref={revenue} height={400} type="line" id="revenue-chart" data={revenueChart} options={revenueChartOptions}></Chart>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-12">
                    <div className="card widget-table">
                        <div className="card-header">
                            <span>En Son Okunan Barkodlar</span>
                        </div>
                        <DataTable className="p-datatable-customers" ref={dt} value={lastReadBarcode} dataKey="idBarcodes" rowHover rows={15} paginator onRowClick={(d) => history.push('/UserSummary/' + d.data.userID)}>
                            <Column field="createdDate" header="Okunma Zamanı" body={(data) => new Date(data.createdDate).toLocaleTimeString('tr', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                                minute: 'numeric',
                                hour: 'numeric',
                                second: 'numeric'
                            })}></Column>
                            <Column style={{ cursor: 'pointer' }} field="nameSurname" header="İsim Soyisim" body={(data) => data.nameSurname}></Column>
                            <Column field="productTypeName" header="Ürün Adı" body={(data) => data.productTypeName}></Column>
                            <Column field="sizeTypeName" header="Ambalaj Türü" body={(data) => data.sizeTypeName}></Column>
                            <Column field="point" header="Puan" body={(data) => data.point + ' PP'}></Column>
                            <Column field="batchNumber" header="Batch" body={(data) => data.batchNumber}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DashboardSeller, comparisonFn);