import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import Products from './pages/Products';
import Orders from './pages/Orders';
import Users from './pages/Users';
import UserSummary from './pages/UserSummary';
import HelpContent from './pages/HelpContent';


import Invoice from './pages/Invoice';
import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';

import PrimeReact, { locale, addLocale } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import DashboardSeller from './components/DashboardSeller';

const App = (props) => {

    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;
    addLocale('tr', {
        "startsWith": "Başlangıç",
        "contains": "Barındırır",
        "notContains": "İçinde Barındırmaz",
        "endsWith": "Bitiş",
        "equals": "Eşittir",
        "notEquals": "Eşit Değildir",
        "noFilter": "Filtresiz",
        "lt": "Daha az",
        "lte": "Daha az veya Eşit",
        "gt": "Daha Fazla",
        "gte": "Daha fazla veya Eşit",
        "dateIs": "Tarih",
        "dateIsNot": "Tarih değildir",
        "dateBefore": "Tarihten önce",
        "dateAfter": "Tarihten sonra",
        "custom": "Özel",
        "clear": "Temizle",
        "apply": "Uygula",
        "matchAll": "Tümüyle eşleşir",
        "matchAny": "Herhangi birine eşleşir",
        "addRule": "Kural Ekle",
        "removeRule": "Kuralı Sil",
        "accept": "Tamam",
        "reject": "İptal",
        "choose": "Seç",
        "upload": "Yükle",
        "cancel": "Vazgeç",
        "dayNames": ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
        "dayNamesShort": ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
        "dayNamesMin": ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
        "monthNames": ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        "monthNamesShort": ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
        "today": "Bugün",
        "weekHeader": "Hf",
        "firstDayOfWeek": 0,
        "dateFormat": "dd/mm/yy",
        "weak": "Zayıf",
        "medium": "Orta",
        "strong": "Güçlü",
        "passwordPrompt": "Şifre Giriniz",
        "emptyFilterMessage": "Kullanılabilir seçenek yok",
        "emptyMessage": "Sonuç bulunamadı",
        "aria": {
            "trueLabel": "Doğru",
            "falseLabel": "Yanlış",
            "nullLabel": "Seçilmedi",
            "pageLabel": "Sayfa",
            "firstPageLabel": "İlk Sayfa",
            "lastPageLabel": "Son Sayfa",
            "nextPageLabel": "Sonraki Sayfa",
            "previousPageLabel": "Önceki Sayfa"
        }

    });
    locale('tr')

    const menu = [
        {
            label: 'Ana Ekran', icon: 'pi pi-home',
            items: [
                { label: 'Dashboard Usta', icon: 'pi pi-home', to: '/' },
                { label: 'Dashboard Tezgahtar', icon: 'pi pi-home', to: '/DashboardSeller' },
                { label: 'Ürünler', icon: 'pi pi-box', to: '/Products' },
                { label: 'Yardım İçerikleri', icon: 'pi pi-box', to: '/HelpContent' },
                { label: 'Siparişler', icon: 'pi pi-box', to: '/Orders' },
                { label: 'Kullanıcılar', icon: 'pi pi-box', to: '/Users' }
            ]
        },

    ];

    const routes = [
        { parent: 'Dashboard', label: 'Usta Uygulama Özeti', main: 'Dashboard' },
        { parent: 'DashboardSeller' , label: 'Tezgahtar Uygulama Özeti', main: 'Dashboard' },
        { parent: 'Products', label: 'Ürün Listesi', main: 'Ürünler' },
        { parent: 'HelpContent', label: 'Yardım İçerikleri', main: 'Yardım' },
        { parent: 'Orders', label: 'Sipariş Listesi', main: 'Siparişler' },
        { parent: 'Users', label: 'Kullanıcı Listesi', main: 'Kullanıcılar' },
        { parent: 'UI Kit', label: 'Form Layout' },
        { parent: 'UI Kit', label: 'Input' },
        { parent: 'UI Kit', label: 'Float Label' },
        { parent: 'UI Kit', label: 'Invalid State' },
        { parent: 'UI Kit', label: 'Button' },
        { parent: 'UI Kit', label: 'Table' },
        { parent: 'UI Kit', label: 'List' },
        { parent: 'UI Kit', label: 'Panel' },
        { parent: 'UI Kit', label: 'Tree' },
        { parent: 'UI Kit', label: 'Overlay' },
        { parent: 'UI Kit', label: 'Menu' },
        { parent: 'UI Kit', label: 'Media' },
        { parent: 'UI Kit', label: 'Message' },
        { parent: 'UI Kit', label: 'File' },
        { parent: 'UI Kit', label: 'Chart' },
        { parent: 'UI Kit', label: 'Misc' },
        { parent: 'UI Blocks', label: 'Blocks' },
        { parent: 'Utilities', label: 'Icons' },
        { parent: 'Pages', label: 'Crud' },
        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Start', label: 'Documentation' }
    ];

    let rightMenuClick;
    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)
    }, [menuMode])

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
                setResetActiveIndex(true)
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false)
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false)
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    }

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    }

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    }


    const onRightMenuClick = () => {
        rightMenuClick = true;
    }

    const onRightMenuActiveChange = (active) => {
        setRightMenuActive(active);
    }

    const onConfigClick = () => {
        configClick = true;
    }

    const onConfigButtonClick = (event) => {
        setConfigActive(prevState => !prevState)
        configClick = true;
        event.preventDefault();
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive(prevState => !prevState)
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevState => !prevState)
        } else {
            setStaticMenuMobileActive(prevState => !prevState)
        }

        event.preventDefault();
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false)
        }

        if (sidebarActive) {
            setSidebarStatic(prevState => !prevState)
        }

        event.preventDefault();
    }

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop())
            }, 200);
        }
    }

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    }

    const onMenuClick = () => {
        menuClick = true;
    }

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile(prevState => !prevState);
        event.preventDefault();
    }

    const onRootMenuItemClick = () => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const isOverlay = () => {
        return menuMode === 'overlay';
    }

    const isDesktop = () => {
        return window.innerWidth > 991;
    }


    const onInputClick = () => {
        searchClick = true
    }

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">
                <AppTopbar items={menu} menuMode={menuMode} colorScheme={props.colorScheme} menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive} activeInlineProfile={activeInlineProfile} onTopbarItemClick={onTopbarItemClick} onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave} onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu} onMenuClick={onMenuClick} onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick} resetActiveIndex={resetActiveIndex} />

                <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick} onToggleMenu={onToggleMenu} onMenuClick={onMenuClick} menuMode={menuMode}
                    colorScheme={props.colorScheme} menuActive={menuActive}
                    sidebarActive={sidebarActive} sidebarStatic={sidebarStatic} pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave} onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile} onChangeActiveInlineMenu={onChangeActiveInlineMenu} resetActiveIndex={resetActiveIndex} />

                <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode}
                    onInputClick={onInputClick}
                    searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                <div className="layout-main-content">
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/DashboardSeller" exact component={DashboardSeller} />
                    <Route path="/Products" exact component={Products} />
                    <Route path="/HelpContent" exact component={HelpContent} />
                    <Route path="/Orders" exact component={Orders} />
                    <Route path="/Users" exact component={Users} />
                    <Route path="/UserSummary/:userid/:rolAdi" exact component={UserSummary} />
                    <Route path="/help" component={Help} />
                    <Route path="/invoice" render={() => <Invoice colorMode={props.colorScheme} location={location} />} />
                    <Route path="/empty" component={EmptyPage} />

                </div>

                <AppFooter colorScheme={props.colorScheme} />

            </div>

            <AppConfig configActive={configActive} onConfigButtonClick={onConfigButtonClick} onConfigClick={onConfigClick} menuMode={menuMode} changeMenuMode={onMenuModeChange}
                colorScheme={props.colorScheme} changeColorScheme={props.onColorSchemeChange} theme={props.theme} changeTheme={props.onMenuThemeChange}
                componentTheme={props.componentTheme} changeComponentTheme={props.onComponentThemeChange}
                ripple={ripple} onRippleChange={onRippleChange} />

        </div>
    );

}

export default App;
