import React from 'react';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';

const AppConfig = (props) => {

	const themes = [
		{ name: 'blue', color: '#0F8BFD' },
		{ name: 'green', color: '#0BD18A' },
		{ name: 'magenta', color: '#EC4DBC' },
		{ name: 'orange', color: '#FD9214' },
		{ name: 'purple', color: '#873EFE' },
		{ name: 'red', color: '#FC6161' },
		{ name: 'teal', color: '#00D0DE' },
		{ name: 'yellow', color: '#EEE500' }
	];

	const componentThemes = [
		{ name: 'blue', color: '#0F8BFD' },
		{ name: 'green', color: '#0BD18A' },
		{ name: 'magenta', color: '#EC4DBC' },
		{ name: 'orange', color: '#FD9214' },
		{ name: 'purple', color: '#873EFE' },
		{ name: 'red', color: '#FC6161' },
		{ name: 'teal', color: '#00D0DE' },
		{ name: 'yellow', color: '#EEE500' }
	];

	return (
<></>

	);
}


export default AppConfig;
