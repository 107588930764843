import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ProductService from '../service/ProductService';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';

const Products = () => {

    let emptyProduct = {
        idProduct: null,
        productCategoryName: '',
        name: '',
        description: '',
        price: 0,
        statusid: 2,
        deliveryTypeid: 41,
        image: ''
    };

    const [products, setProducts] = useState(null);
    const [categories, setCategories] = useState([]);
    const [file, setFile] = useState("");

    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);
    const [statusFilterOption, setStatusFilterOption] = useState(1);

    const toast = useRef(null);
    const dt = useRef(null);
    const productService = new ProductService();

    const loadProducts = async (data) => {
        setLoading(true);
        await productService.getProductList(data)
            .then(d => setProducts(d.data.resultSet))
            .finally(() => setLoading(false));
    }

    const updateProduct = async (data) => {
        const formData = new FormData();
        formData.append("addUpdateProductDTO", JSON.stringify(data))
        formData.append("file", file)
        setLoading(true);
        let res;
        await productService.updateProduct(formData)
            .then(d => res = d)
            .finally(() => setLoading(false));
        return await res;
    }

    const updatePassiveProducts = async (data) => {
        setLoading(true);
        let res;
        await productService.updatePassiveProducts(data)
            .then(d => res = d)
            .finally(() => setLoading(false));
        return await res;
    }

    const createProduct = async (data) => {
        const formData = new FormData();
        formData.append("addUpdateProductDTO", JSON.stringify(data))
        formData.append("file", file)
        setLoading(true);
        let res;
        await productService.createProduct(formData)
            .then(d => res = d)
            .finally(() => setLoading(false));
        console.log(res);
        return await res;
    }

    const loadProductCategories = () => {
        setLoading(true);
        productService.getProductCategoryList({})
            .then(data => setCategories(data.data))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadProducts({
            "getAllData": true,
            "searchValue": "",
            "Statusid": 1
        });
        loadProductCategories();
        return () => {
            setProducts([])
            setCategories([])
        }
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('tr', { style: 'decimal' });
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setVisibleRight(true);
        //setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = async () => {
        setSubmitted(true)
        if (!product.name) {
            return
        }

        let res;
        if (product.idProduct) {
            res = await updateProduct({
                "idProduct": product.idProduct,
                "ProductCategoryid": product.productCategoryid,
                "name": product.name,
                "description": product.description,
                "price": product.price,
                "statusid": product.statusid,
                "deliveryTypeid": product.deliveryTypeid
            })
        }
        else {
            res = await createProduct({
                "ProductCategoryid": product.productCategoryid,
                "name": product.name,
                "description": product.description,
                "price": product.price,
                "statusid": product.statusid,
                "deliveryTypeid":product.deliveryTypeid
            })
        }
        await loadProducts({
            "getAllData": true,
            "searchValue": "",
            "statusid": statusFilterOption
        })
        setProductDialog(false);
        if (res.success) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Seçilen ürün başarıyla düzenlendi.', life: 3000 });
            setVisibleRight(false)
            setSubmitted(false)
        } else
            toast.current.show({ severity: 'error', summary: 'Hata', detail: res.messages.join('\n'), life: 3000, style: { 'whiteSpace': 'pre-line' } });
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        //setProductDialog(true);
        setVisibleRight(true)
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = async () => {
        const res = await updateProduct({
            "idProduct": product.idProduct,
            "statusid": 2
        })
        await loadProducts({
            "getAllData": true,
            "searchValue": "",
            "statusid": statusFilterOption
        })
        setDeleteProductDialog(false);
        if (res.success)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Seçilen ürün başarıyla pasif yapıldı.', life: 3000 });
        else
            toast.current.show({ severity: 'error', summary: 'Hata', detail: res.messages.join('\n'), life: 3000, style: { 'whiteSpace': 'pre-line' } });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = async () => {

        const res = await updatePassiveProducts(selectedProducts.map(d => d.idProduct))
        await loadProducts({
            "getAllData": true,
            "searchValue": "",
            "statusid": statusFilterOption
        })

        setDeleteProductsDialog(false);
        setSelectedProducts(null);

        if (res.success)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Seçilen ürün(ler) başarıyla pasif yapıldı.', life: 3000 });
        else
            toast.current.show({ severity: 'error', summary: 'Hata', detail: res.messages.join('\n'), life: 3000, style: { 'whiteSpace': 'pre-line' } });

    }

    const onCategoryChange = (e) => {
        let _product = { ...product };
        _product['productCategoryid'] = e.value;
        setProduct(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Dropdown
                    className='ml-2'
                    value={statusFilterOption}
                    onChange={(e) => {
                        loadProducts({ "getAllData": true, "searchValue": "", "statusid": e.value })
                        setStatusFilterOption(e.value)
                    }}
                    options={[{ name: 'Tüm Ürünleri Göster', value: null }, { name: 'Sadece Aktif Ürünleri Göster', value: 1 }, { name: 'Sadece Pasif Ürünleri Göster', value: 2 }]}
                    optionLabel="name"
                    placeholder="Tüm Ürünleri Göster" />

            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Yeni Ürün" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Seçileni Pasif Yap" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.idProduct}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">İsim</span>
                {rowData.name}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Resim</span>
                <img src={(rowData.image != null && rowData.image.startsWith("http")) ? rowData.image : `https://api.ustacuzdan.com.tr/uploads/product/${rowData.idProduct}/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Para Puan</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Kategori</span>
                {rowData.productCategoryName}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Durum</span>
                <span className={`product-badge status-${rowData.statusid}`}>{rowData.statusid == 1 ? 'Aktif' : 'Pasif'}</span>
            </>
        )
    }

    const deliveryTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Teslimat Tipi</span>
                <span className={`deliverytype-${rowData.deliveryTypeid}`}>{rowData.deliveryTypeid == 41 ? 'Adrese Teslim' : 'Banka Hesabına Havale'}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Ürünleri Yönet</h5>
            <div className="flex flex-row ">
                <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
                <Button label="Excel Aktar" icon="pi pi-upload" className="p-button-raised p-button-secondary" onClick={exportCSV} />
            </div>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{option.name}</span>
            </div>
        );
    }
    const representativeFilterTemplate = (options) => {
        return (<>
            <div className="mb-3 text-bold">Duruma Göre Filtre</div>
            <MultiSelect value={options.value} options={[{ name: 'Aktif', value: 1 }, { name: 'Pasif', value: 0 }]} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Tümü" className="p-column-filter" />
        </>
        )
    }
    const categoryItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{option.value}</span>
            </div>
        );
    }
    const categoryFilterTemplate = (options) => {
        return (<>
            <div className="mb-3 text-bold">Kategoriye Göre Filtre</div>
            <MultiSelect value={options.value} options={categories} itemTemplate={categoryItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="value" placeholder="Tümü" className="p-column-filter" />
        </>
        )
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable loading={loading} ref={dt} stripedRows showGridlines value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="idProduct" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 100]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} toplam üründen, {first} ile {last} arasındaki kayıtlar gösteriliyor. "
                        globalFilter={globalFilter} emptyMessage="Ürün bulunamadı." header={header} responsiveLayout="scroll"
                        sortField="idProduct" sortOrder={-1}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="idProduct" header="ID" sortable body={codeBodyTemplate} headerStyle={{ width: '4%', minWidth: '5rem' }}></Column>
                        <Column field="name" header="İsim" filter filterPlaceholder="Ürün adı ile ara" sortable body={nameBodyTemplate} headerStyle={{}}></Column>
                        <Column header="Resim" body={imageBodyTemplate} headerStyle={{ width: '8%', minWidth: '9rem', }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="price" header="Para Puan" body={priceBodyTemplate} sortable headerStyle={{ width: '8%', minWidth: '9rem' }}></Column>
                        <Column field="productCategoryName" header="Kategori" filterField='productCategoryName' showFilterMatchModes={false} showFilterMenuOptions={false} filterMatchMode='equals' sortable body={categoryBodyTemplate} headerStyle={{ width: '13%', minWidth: '13rem' }}
                            filter filterElement={categoryFilterTemplate}></Column>
                        <Column field="deliveryTypeid" header="Teslimat Tipi" filterField="deliveryTypeid" body={deliveryTypeBodyTemplate} headerStyle={{ width: '8%', minWidth: '9rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

                        <Column field="statusid" header="Durum" filterField="statusid" body={statusBodyTemplate} headerStyle={{ width: '8%', minWidth: '9rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '1%', minWidth: '10rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>

                    <Sidebar visible={visibleRight} onHide={() => setVisibleRight(false)} style={{ width: '450px' }} baseZIndex={1000} position="right">
                        <div className="card p-fluid">
                            <h5>Temel Bilgiler</h5>
                            <div className="field grid">
                                <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">İsim:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !product.name })} />
                                    {submitted && !product.name && <small className="p-error">İsim alanı doldurulmak zorunda</small>}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="description" >Açıklama:</label>
                                <InputTextarea id="description" autoResize value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} className={classNames({ 'p-invalid': !product.description })} />
                                {submitted && !product.description && <small className="p-error">Açıklama alanı doldurulmak zorunda</small>}
                            </div>
                            <div className="field grid">
                                <label htmlFor="price" className="col-12 mb-4 md:col-4 md:mb-0">Para Puan:</label>
                                <div className="col-12 md:col-8">
                                    <div className="p-inputgroup">
                                        <InputNumber id="price" value={product.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="decimal" locale="tr" className={classNames({ 'p-invalid': !(product.price > 0) })} />
                                        <span className="p-inputgroup-addon">Puan</span>
                                    </div>
                                    {submitted && !(product.price > 0) && <small className="p-error">Para Puan 0'dan büyük olmak zorunda</small>}

                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="statusid" className="col-12 mb-4 md:col-4 md:mb-0">Durum:</label>
                                <div className="col-12 md:col-8">
                                    <Dropdown
                                        id='statusid'
                                        value={product.statusid}
                                        onChange={(e) => onInputNumberChange(e, 'statusid')}
                                        options={[{ name: 'Aktif', value: 1 }, { name: 'Pasif', value: 2 }]}
                                        optionLabel="name"
                                        placeholder="Select" />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="deliveryTypeid" className="col-12 mb-4 md:col-4 md:mb-0">Teslimat Tipi:</label>
                                <div className="col-12 md:col-8">
                                    <Dropdown
                                        id='deliveryTypeid'
                                        value={product.deliveryTypeid}
                                        onChange={(e) => onInputNumberChange(e, 'deliveryTypeid')}
                                        options={[{ name: 'Adrese Teslim', value: 41 }, { name: 'Banka Hesabına Havale', value: 42 }]}
                                        optionLabel="name"
                                        placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div className="card p-fluid">
                            <h6>Kategori</h6>
                            <div className="field">
                                <div className="formgrid grid">
                                    {categories.map((category, index) =>
                                        <div className="field-radiobutton col-6" key={index}>
                                            <RadioButton inputId={category.idProductCategory} name="category" value={category.idProductCategory} onChange={onCategoryChange} checked={product.productCategoryid === category.idProductCategory} />
                                            <label htmlFor={category.idProductCategory}>{category.value}</label>
                                        </div>
                                    )}
                                </div>
                                {submitted && !(product.productCategoryid > 0) && <small className="p-error">Kategori seçilmek zorunda</small>}

                            </div>
                        </div>
                        <div className='card p-fluid'>

                            <FileUpload id='file' mode="basic" name="file" accept="image/*" maxFileSize={1000000} onSelect={(e) => setFile(e.files[0])} />
                        </div>

                        {product.image && <img src={(product.image != null && product.image.startsWith("http")) ? product.image : `https://api.ustacuzdan.com.tr/uploads/product/${product.idProduct}/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className='flex justify-content-between'>
                            <Button className="p-button-raised p-button-secondary mr-2 mb-2" label="İptal" icon="pi pi-times" onClick={() => setVisibleRight(false)} />
                            <Button className="p-button-outlined mr-2 mb-2" label="Kaydet" icon="pi pi-check" onClick={saveProduct} />
                        </div>
                    </Sidebar>

                    <Dialog visible={productDialog} style={{ width: '450px' }} header="Ürün Bilgileri" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {product.image && <img src={`${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="name">İsim</label>
                            <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} />
                            {submitted && !product.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Kısa Açıklama</label>
                            <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={5} cols={20} />
                        </div>

                        <div className="field">
                            <label className="mb-3">Kategori</label>
                            <div className="formgrid grid">
                                {categories.map((category, index) =>
                                    <div className="field-radiobutton col-6" key={index}>
                                        <RadioButton inputId={category.idProductCategory} name="category" value={category.value} onChange={onCategoryChange} checked={product.productCategoryid === category.idProductCategory} />
                                        <label htmlFor="category1">{category.value}</label>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="price">Para Puan</label>
                                <InputNumber id="price" value={product.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="decimal" locale="tr" />
                            </div>
                            <div className="field col">
                                <label htmlFor="quantity">Stok</label>
                                <InputNumber id="quantity" value={product.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} integeronly />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span><b>{product.name}</b> isimli ürünü pasif yapmak istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Seçili olan ürünleri pasif yapmak istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Products, comparisonFn);