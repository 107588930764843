import axios from 'axios';
import LocalStorageService from "./LocalStorageService";
const localStorageService = LocalStorageService.getService();

const URL = 'https://api.ustacuzdan.com.tr/';
//const URL = 'http://localhost:17176/';

const axiosBase = axios.create({
    baseURL: URL,
});

axiosBase.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401){
        window.location.href = "#/login"
        localStorageService.clearUser()
    }
    return Promise.reject(error);
});

axiosBase.interceptors.request.use(
    async (config) => {
        config.headers["UserType"] = 9;
        config.headers["Company"] = 1;
        const token = localStorageService.getaccesstoken();
        if (token)
            config.headers.Authorization = 'Bearer ' + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosBase.defaults.params = {};

export default axiosBase;