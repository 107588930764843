import axiosBase from './AxiosBase';

export default class DashboardService {
    async getUserPointSummary(data) {
        return axiosBase.post('Dashboard/UserPointSummary', data).then(res => res.data);
    }

    async getMonthlyPointSummary(appID, data) {
        return axiosBase.post('Dashboard/MonthlyPointSummary/' + appID + '/' + data).then(res => res.data);
    }

    async getYearlySummary(appID, data) {
        return axiosBase.post('Dashboard/YearlySummary/' + appID + '/' + data).then(res => res.data);
    }

    async getBarcodeStatus(appID) {
        return axiosBase.post('Dashboard/BarcodeStatus/' + appID).then(res => res.data);
    }

    async getTopPointEarnedUser(appID, count, period) {
        return axiosBase.post('Dashboard/TopPointEarnedUser/' + appID + '/' + count + '/' + period).then(res => res.data);
    }

    async getTopProductPoint(appID, count, period, userid = 0, order = "point") {
        let url = 'Dashboard/TopProductPoint/' + appID + '/' + count + '/' + period + '?order=' + order;
        if (userid != 0)
            url = url + '&userid=' + userid
        return axiosBase.post(url).then(res => res.data);
    }

    async getTopPointAmountUsedUser(appID, count, type) {
        return axiosBase.post('Dashboard/TopPointAmountUsedUser/' + appID + '/' + count + '/' + type).then(res => res.data);
    }

    async getDailyUserCount(appID, lastHowManyDays) {
        return axiosBase.post('Dashboard/DailyUserCount/' + appID + '/' + lastHowManyDays).then(res => res.data);
    }

    async getLastReadBarcode(appID, count, userid = 0) {
        let url = 'Dashboard/LastReadBarcode/' + appID + '/' + count;
        if (userid != 0)
            url = url + '/?userid=' + userid
        return axiosBase.post(url).then(res => res.data);
    }


    async getPointMonthlyPercentChange(data) {
        return axiosBase.post('Dashboard/PointMonthlyPercentChange/' + data).then(res => res.data);
    }






}
