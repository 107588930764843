import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import HelpService from '../service/HelpService';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';

import { Divider } from 'primereact/divider';



const HelpContent = () => {
    const [expandedRows, setExpandedRows] = useState(null);

    let emptyData = {
        idHelpContent: 0,
        title: '',
        content: '',
        order: 0,
        helpCategoryid: null
    };
    let emptyDataCategory = {
        idHelpCategory: 0,
        name: 'Ana Kategori',
        order: 0,
        parentid: null,
        appTypeid: 9
    };

    const [datas, setDatas] = useState(null);
    const [data, setData] = useState(emptyData);
    const [dataCategory, setDataCategory] = useState(emptyDataCategory);
    const [mainCategory, setMainCategory] = useState([emptyDataCategory]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);
    const [visibleCategoryRight, setVisibleCategoryRight] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const helpService = new HelpService();


    const loadDatas = async (data) => {
        setLoading(true);
        await helpService.getHelpList(data)
            .then(d => setDatas(d.data))
            .finally(() => setLoading(false));
    }
    const loadMainCategoryDatas = async (data) => {
        setLoading(true);
        await helpService.getHelpCategoryList(data)
            .then(d =>
                setMainCategory((prev) => [...prev, ...d.data])

            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadDatas(9);
        loadMainCategoryDatas({ "Parentid": null });
        return () => {
            setDatas([])
            setMainCategory([])
        }
    }, []);

    const saveData = async () => {
        setSubmitted(true)

        if (data.content == null || data.content == "" || data.title == null || data.title == "" || data.helpCategoryid == null || data.helpCategoryid == 0)
            return null;

        if (data.idHelpContent == null || data.idHelpContent == 0)
            helpService.addHelpContent(data).then((res) =>
                res.success ?
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'İçerik ekleme işlemi başarılıyle tamamlanmıştır.' }) :
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İçerik düzenleme işlemi sırasında hata oluştu.' })
            ).finally(() => loadDatas(9));
        else
            helpService.updateHelpContent(data).then((res) =>
                res.success ?
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'İçerik düzenleme işlemi başarılıyle tamamlanmıştır.' }) :
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İçerik düzenleme işlemi sırasında hata oluştu.' })
            ).finally(() => loadDatas(9));

        setVisibleRight(false);

    }

    const saveDataCategory = async () => {
        setSubmitted(true)
        if (dataCategory.name == null || dataCategory.name == "")
            return null;

        if (dataCategory.idHelpCategory == null || dataCategory.idHelpCategory == 0)
            helpService.addHelpCategory(dataCategory).then((res) =>
                res.success ?
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kategori ekleme işlemi başarılıyle tamamlanmıştır.' }) :
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kategori düzenleme işlemi sırasında hata oluştu.' })
            ).finally(() => loadDatas(9));
        else
            helpService.updateHelpCategory(dataCategory).then((res) =>
                res.success ?
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kategori düzenleme işlemi başarılıyle tamamlanmıştır.' }) :
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kategori düzenleme işlemi sırasında hata oluştu.' })
            ).finally(() => loadDatas(9));

        setVisibleCategoryRight(false);


    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDataCategory(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Veri Listesi</h5>
            <div className="flex flex-row ">
                <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const onCategoryChange = (e) => {
        let _data = { ...data };
        _data['helpCategoryid'] = e.value;
        setData(_data);
    }

    const onCategoryChangeCategory = (e) => {
        let _data = { ...dataCategory };
        _data['parentid'] = e.value;
        setDataCategory(_data);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _data = { ...data };
        _data[`${name}`] = val;

        setData(_data);
    }
    const onInputChangeCategory = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _data = { ...dataCategory };
        _data[`${name}`] = val;

        setDataCategory(_data);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _data = { ...data };
        _data[`${name}`] = val;

        setData(_data);
    }

    const onInputNumberChangeCategory = (e, name) => {
        const val = e.value || 0;
        let _data = { ...dataCategory };
        _data[`${name}`] = val;

        setDataCategory(_data);
    }

    const openNew = () => {
        setData(emptyData);
        setSubmitted(false);
        setVisibleRight(true);
        //setProductDialog(true);
    }

    const editData = (data) => {
        setData({ ...data });
        setVisibleRight(true)
    }

    const editDataCategory = (data) => {
        console.log(data);
        setDataCategory({ ...data });
        setVisibleCategoryRight(true)
    }

    const rowExpansionTemplate = (data) => {
        return (
            <>
                {
                    data.helpCategories?.map((item, index) =>
                        <div className="p-3" key={index}>
                            <h4 className='mb-4'>{item.name} <Button icon="pi pi-pencil" label='Düzenle' className="p-button-secondary ml-2" onClick={() => editDataCategory(item)} /></h4>
                            {item.helps?.map((e, index) =>
                                <div className='pl-4' key={index}>
                                    <h6>{e.title}</h6>
                                    <p>{e.content}</p>

                                    <Button icon="pi pi-pencil" className="p-button-outlined p-button-secondary mr-2" label='Düzenle' onClick={() => editData({ ...e, ...{ "helpCategoryid": item.idHelpCategory } })} />
                                    <Divider />
                                </div>
                            )}
                        </div>
                    )
                }

            </>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData.children.length > 0;
    };

    const expandAll = () => {
        let _expandedRows = {};

        datas.forEach((p) => (_expandedRows[`${p.idHelpCategory}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Toolbar className="mb-4" left={() =>
                        <div className="flex flex-wrap justify-content-end gap-2">
                            <Button icon="pi pi-plus" style={{ marginRight: 10 }} label="Tüm Kategorileri Aç" onClick={expandAll} text />
                            <Button icon="pi pi-minus" label="Tüm Kategorileri Kapat" onClick={collapseAll} text />
                        </div>
                    } right={() =>
                        <>
                            <Button label="Yeni Kategori" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => {
                                setDataCategory(emptyDataCategory);
                                setSubmitted(false);
                                setVisibleCategoryRight(true);
                            }} />
                            <Button label="Yeni İçerik" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        </>
                    }></Toolbar>

                    <DataTable loading={loading} ref={dt} stripedRows showGridlines value={datas}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        globalFilter={globalFilter} emptyMessage="Veri bulunamadı."
                        header={header}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="idHelpCategory"
                    >
                        <Column expander={allowExpansion} style={{ width: '5rem' }} />
                        <Column field="idHelpCategory" header="ID" body={(rowData) => rowData.idHelpCategory} headerStyle={{ width: '4%', minWidth: '5rem' }}></Column>
                        <Column expander field="name" header="İsim" body={(rowData) => rowData.name}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: 150, minWidth: '10rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

                    </DataTable>

                    <Sidebar visible={visibleRight} onHide={() => setVisibleRight(false)} style={{ width: '450px' }} baseZIndex={1000} position="right">
                        <div className="card p-fluid">
                            <h5>Temel Bilgiler</h5>
                            <div className="field grid">
                                <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">Başlık:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="title" value={data.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': !data.title })} />
                                    {submitted && !data.title && <small className="p-error">Başlık alanı doldurulmak zorunda</small>}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="content" >Metin:</label>
                                <InputTextarea id="content" autoResize value={data.content} onChange={(e) => onInputChange(e, 'content')} required rows={5} className={classNames({ 'p-invalid': !data.content })} />
                                {submitted && !data.content && <small className="p-error">Metin alanı doldurulmak zorunda</small>}
                            </div>

                            <div className="field grid">
                                <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">Sıra:</label>
                                <div className="col-12 md:col-10">
                                    <InputNumber id="order" value={data.order} onChange={(e) => onInputNumberChange(e, 'order')} required autoFocus className={classNames({ 'p-invalid': !data.order })} />
                                    {submitted && !data.order && <small className="p-error">Sıra numarası doldurulmak zorunda</small>}
                                </div>
                            </div>
                        </div>
                        <div className="card p-fluid">
                            <h6>Kategori</h6>
                            <div className="field">
                                <div className="formgrid grid pl-2">
                                    {datas != null && <Dropdown value={data.helpCategoryid} onChange={(e) => onCategoryChange(e)} options={datas} optionLabel="name"
                                        optionValue='idHelpCategory' itemTemplate={(option) =>
                                            <div className="p-d-flex p-ai-center ">
                                                <i className='pi pi-angle-right'></i> {option.name}
                                            </div>
                                        }
                                        optionGroupLabel="name" optionGroupChildren="helpCategories" optionGroupTemplate={(option) =>
                                            <div className="p-d-flex p-ai-center">
                                                 {option.name}
                                            </div>
                                        } placeholder="Kategori Seçiniz" />
                                    }

                                </div>
                                {submitted && !data.helpCategoryid && <div className='mt-2'><small className="p-error">Kategori seçilmek zorunda</small></div>}

                            </div>
                        </div>
                        <div className='flex justify-content-between'>
                            <Button className="p-button-raised p-button-secondary mr-2 mb-2" label="İptal" icon="pi pi-times" onClick={() => setVisibleRight(false)} />
                            <Button className="p-button-outlined mr-2 mb-2" label="Kaydet" icon="pi pi-check" onClick={saveData} />
                        </div>
                    </Sidebar>

                    <Sidebar visible={visibleCategoryRight} onHide={() => setVisibleCategoryRight(false)} style={{ width: '450px' }} baseZIndex={1000} position="right">
                        <div className="card p-fluid">
                            <h5>Temel Bilgiler</h5>
                            <div className="field grid">
                                <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">Başlık:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="title" value={dataCategory.name} onChange={(e) => onInputChangeCategory(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !dataCategory.name })} />
                                    {submitted && !dataCategory.name && <small className="p-error">İsim alanı doldurulmak zorunda</small>}
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">Sıra:</label>
                                <div className="col-12 md:col-10">
                                    <InputNumber id="order" value={dataCategory.order} onChange={(e) => onInputNumberChangeCategory(e, 'order')} required autoFocus className={classNames({ 'p-invalid': !dataCategory.order })} />
                                    {submitted && !dataCategory.order && <small className="p-error">Sıra numarası doldurulmak zorunda</small>}
                                </div>
                            </div>

                        </div>
                        <div className="card p-fluid">
                            <h6>Üst Kategori</h6>
                            <div className="field">
                                <div className="formgrid grid pl-2">
                                    {mainCategory.length > 0 && <Dropdown value={dataCategory.parentid} onChange={(e) => onCategoryChangeCategory(e)} options={mainCategory} optionLabel="name"
                                        optionValue='idHelpCategory' placeholder="Kategori Seçiniz" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-content-between'>
                            <Button className="p-button-raised p-button-secondary mr-2 mb-2" label="İptal" icon="pi pi-times" onClick={() => setVisibleCategoryRight(false)} />
                            <Button className="p-button-outlined mr-2 mb-2" label="Kaydet" icon="pi pi-check" onClick={saveDataCategory} />
                        </div>
                    </Sidebar>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(HelpContent, comparisonFn);