import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import UserService from '../service/UserService';
import DashboardService from '../service/DashboardService';
import { Timeline } from 'primereact/timeline';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Sidebar } from 'primereact/sidebar';
import OrderDetailsComponent from '../pages/OrderDetailsComponent';
import { Button } from 'primereact/button';

const countryChart = {
    labels: [''],
    datasets: [
        {
            data: [0],
            backgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            hoverBackgroundColor: [
                '#0F8BFD',
                '#545C6B',
                '#EC4DBC',
                '#EEE500',
                '#FC6161',
                '#00D0DE',
                '#873EFE',
            ],
            borderColor: 'transparent',
            fill: true
        }
    ]
};
const countryChartOptions = {
    responsive: true
};

const UserSummary = () => {
    let { userid, rolAdi } = useParams();

    const [loading, setLoading] = useState(false);
    const [lastReadBarcode, setLastReadBarcode] = useState([]);
    const [userSummary, setUserSummary] = useState(null);
    const [selectedTopProductPeriod, setSelectedTopProductPeriod] = useState(30);
    const [topProductPoint, setTopProductPoint] = useState([]);

    const [visibleRight, setVisibleRight] = useState(false);
    const [orderID, setOrderID] = useState(null);

    const dt = useRef(null)
    const ProductPointPie = useRef(null);
    let appID = 9;

    const userService = new UserService();
    const dashboardService = new DashboardService();

    useEffect(() => {
        if (rolAdi != "Usta")
            appID = 43

        setLoading(true);

        ChangeTopProduct(0);

        dashboardService.getLastReadBarcode(appID, 50, userid).then(res => {
            setLastReadBarcode(res.data)
        })

        userService.getUserSummary(userid).then(res => {
            setUserSummary(res.data)
        }).finally(() => setLoading(false))


        return () => {
            setLastReadBarcode([])
            setUserSummary(null)
        }
    }, [])

    const changeTopProductChart = (event) => {
        setSelectedTopProductPeriod(event.value)
        ChangeTopProduct(event.value);
    };

    const ChangeTopProduct = async (period) => {
        dashboardService.getTopProductPoint(appID, 10, period, userid).then(res => {
            setTopProductPoint(res.data)
            countryChart.labels = res.data.map(item => item.productTypeTxt)
            countryChart.datasets[0].data = res.data.map(item => item.totalProduct)
            ProductPointPie?.current?.refresh();
        })
    }
    const marker = (item) => {
        return (
            <span className="custom-marker" style={{ backgroundColor: "#0BD18A" }}>
                <i className={"pi pi-shopping-cart"}></i>
            </span>
        )
    }

    const content = (item) => {
        return (
            <>
                <div className="flex align-items-center justify-content-between">
                    <p className='cursor-pointer' onClick={() => { setVisibleRight(true); setOrderID(item.idUserOrder) }}>{item.productDTO?.name.slice(0, 40)}... #{item.idUserOrder}</p>
                    <h6 style={{ color: item.amountColor }}> {item.price.toLocaleString('tr', { style: 'decimal' })} PP</h6>
                </div>

                <div className="flex flex-column ">
                    <span>{new Date(item.createdDate).toLocaleTimeString('tr', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        minute: 'numeric',
                        hour: 'numeric',
                        second: 'numeric'
                    })}</span>
                    <span>{item.userDTO.nameSurname} {item.userAddressDTO != null && <span>- {item.userAddressDTO?.ilceTxt} / {item.userAddressDTO?.sehirTxt}</span>}</span>
                </div>
            </>
        )
    }

    return (
        <div className="grid">
            <div className="md:col-7">
                {loading && <div className='flex justify-content-center'>
                    <ProgressSpinner strokeWidth="1" className='flex align-items-center justify-content-center' />
                </div>}
                {!loading && <Card title={userSummary?.nameSurname} subTitle={userSummary?.mobilePPhone}>
                    <p><strong>Doğum günü:</strong> {userSummary?.birthday != null ? new Date(userSummary?.birthday).toLocaleDateString('tr', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                    }) : "Doğum günü girilmemiş"}</p>
                    <p><strong>E-Posta:</strong> {userSummary?.mail}</p>
                    <p><strong>Telefon Numarası:</strong> {userSummary?.mobilePhone}</p>

                    <div className="graph-content grid">
                        <div className="col-12 md:col-4">
                            <h3>{userSummary?.pointDTO?.amountPoint.toLocaleString('tr', { style: 'decimal' })}{userSummary?.pointDTO == null && "0"} PP</h3>
                            <h6>Aktif Puan</h6>
                            <p>Ustanın yüklediği fakat kullanmadığı puanlar toplamı</p>
                        </div>
                        <div className="col-12 md:col-4">
                            <h3>{userSummary?.pointDTO?.usedPoint.toLocaleString('tr', { style: 'decimal' })}{userSummary?.pointDTO == null && "0"} PP</h3>
                            <h6>Kullanılmış Puan</h6>
                            <p>Ustanın kullanmadığı puanlar toplamı</p>
                        </div>
                        <div className="col-12 md:col-4">
                            <h3>{userSummary?.orderDTOs.length.toLocaleString('tr', { style: 'decimal' })}</h3>
                            <h6>Toplam Sipariş</h6>
                            <p>Bu zamana kadar vermiş olduğu tüm siparişler toplamı</p>
                        </div>
                    </div>
                </Card>}
            </div>
            <div className='md:col-5'>
                <div className="card widget-country-graph">
                    <div className="card-header">
                        <span>Ürün / Yüklenen Puan Dağılımı</span>
                        <Dropdown
                            options={[{ name: 'Son 90 Gün', value: 90 }, { name: 'Son 60 Gün', value: 60 }, { name: 'Son 30 Gün', value: 30 }, { name: 'Tüm Zamanlar', value: 0 }]}
                            value={selectedTopProductPeriod}
                            optionLabel="name"
                            onChange={changeTopProductChart} />
                    </div>
                    <p>En çok puan yüklenen ürünler</p>
                    {loading && <div className='flex justify-content-center'>
                        <ProgressSpinner strokeWidth="1" className='flex align-items-center justify-content-center' />
                    </div>}
                    {!loading &&
                        <>
                            <div className="country-graph flex justify-content-center">
                                <Chart type="doughnut" ref={ProductPointPie} id="country-chart" data={countryChart} options={countryChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                            </div>
                            <div className="country-content">
                                <ul>
                                    {topProductPoint.length == 0 && <li>Henüz okutma yapılmamış.</li>}
                                    {topProductPoint.map((item, index) =>
                                        <li className="flex justify-content-between align-items-center" key={index}>
                                            <div className="flex justify-content-between align-items-center">
                                                <div className="color" style={{ backgroundColor: '' + countryChart.datasets[0].backgroundColor[index] + '', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                                <div className="flex flex-column justify-content-start align-items-start">
                                                    <span>{item.productTypeTxt}</span>
                                                    <span>{item.earnedPoint.toLocaleString('tr', { style: 'decimal' })} Puan</span>
                                                </div>
                                            </div>
                                            <span>{item.totalProduct.toLocaleString('tr', { style: 'decimal' })} Adet</span>
                                        </li>

                                    )}
                                </ul>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="md:col-12">
                <div className="card widget-table">
                    <div className="card-header">
                        <span>En Son Okuttuğu Barkodlar</span>
                    </div>
                    <DataTable loading={loading} className="p-datatable-customers" ref={dt} value={lastReadBarcode} dataKey="idBarcode" rowHover rows={15} paginator>
                        <Column field="createdDate" header="Okunma Zamanı" body={(data) => new Date(data.createdDate).toLocaleTimeString('tr', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                            minute: 'numeric',
                            hour: 'numeric',
                            second: 'numeric'
                        })}></Column>
                        <Column field="productTypeName" header="Ürün Adı" body={(data) => data.productTypeName}></Column>
                        <Column field="sizeTypeName" header="Ambalaj Türü" body={(data) => data.sizeTypeName}></Column>
                        <Column field="point" header="Puan" body={(data) => data.point + ' PP'}></Column>
                        <Column field="batchNumber" header="Batch" body={(data) => data.batchNumber}></Column>
                    </DataTable>
                </div>
            </div>


            <div className="md:col-12">
                <div className="card widget-table">

                    <DataTable loading={loading} className="p-datatable-customers" value={userSummary?.pointHistoryDTOs}
                        dataKey="createdDate" rowHover rows={25} paginator responsiveLayout="scroll"
                        header="Hesap Hareketleri" showGridlines stripedRows sortField="createdDate" sortOrder={-1}>
                        <Column field="createdDate" style={{ width: 200 }} header="İşlem Zamanı" body={(data) => new Date(data.createdDate).toLocaleTimeString('tr', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                            minute: 'numeric',
                            hour: 'numeric',
                            second: 'numeric'
                        })} sortable></Column>
                        <Column field="product" header="Ürün" body={(data) => data.product} sortable></Column>
                        <Column field="barcode" header="Barkod" body={(data) => data.barcode}></Column>
                        <Column field="point" header="Puan" body={(data) => data.point + ' PP'} sortable></Column>
                        <Column field="amount" header="Bakiye" body={(data) => data.amount + ' PP'} sortable></Column>
                        <Column field="extraPoint" header="Puan Türü" body={(data) => data.extraPoint} sortable></Column>
                    </DataTable>
                </div>
            </div>

            <div className=" md:col-12">
                <div className="card widget-timeline">
                    <div className="timeline-header flex justify-content-between align-items-center">
                        <p>Siparişler</p>
                    </div>
                    <div className="timeline-content">
                        {loading && <div className='flex justify-content-center'>
                            <ProgressSpinner strokeWidth="1" className='flex align-items-center justify-content-center' />
                        </div>}
                        {!loading && <><Timeline value={userSummary?.orderDTOs.reverse()} marker={marker} content={content} className="custimized-timeline" />
                            {userSummary?.orderDTOs.length == 0 && <div className='p-3'>Sonuç bulanamadı</div>}</>
                        }
                    </div>
                    <div className="timeline-footer flex align-items-center justify-content-center">
                        <button className="p-link">Tüm Siparişler <i className="pi pi-arrow-down"></i></button>
                    </div>
                </div>

            </div>

            <Sidebar visible={visibleRight} onHide={() => setVisibleRight(false)} style={{ width: '450px' }} baseZIndex={1000} position="right">
                <div className="card p-fluid">
                    <h5>Bilgi</h5>
                    <div>Siparişle ilgili sadece durum değişikliği yapabilirsiniz.</div>
                </div>
                <OrderDetailsComponent orderID={orderID} />


                <div className='flex justify-content-between'>
                    <Button className="p-button-raised p-button-secondary mr-2 mb-2" label="Kapat" icon="pi pi-times" onClick={() => setVisibleRight(false)} />
                </div>
            </Sidebar>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserSummary, comparisonFn);